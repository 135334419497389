import PropTypes from 'prop-types';

import Container from 'containers/Container';

import { ClassNames } from 'services/classname.service';

import styles from './FDICLogo.module.scss';

function FDICLogo({
	isMobile
}) {
	return (
		<>
			{ !isMobile && (
				<Container className={ ClassNames( [ styles[ 'fdic-container' ], styles[ ' fdic-container--desktop' ] ] ) } wide={ true }>
					<img
						className={ styles[ 'fdic-logo--desktop' ] }
						src="/fdic-desktop.svg"
						width="1322"
						height="54"
						alt="FDIC-Insured &ndash; Backed by the full faith and credit of the U.S. Government"
					/>
				</Container>
			)}

			{ isMobile && (
				<Container className={ ClassNames( [ styles[ 'fdic-container' ], styles[ 'fdic-container--mobile' ] ] ) } wide={ true }>
					<img
						className={ styles[ 'fdic-logo--mobile' ] }
						src="/fdic-mobile.svg"
						width="600"
						height="66"
						alt="FDIC-Insured &ndash; Backed by the full faith and credit of the U.S. Government"
					/>
				</Container>
			)}
		</>
	);
}

FDICLogo.propTypes = {
	isMobile: PropTypes.bool
};

export default FDICLogo;
